import axios from 'axios';

const BASE_URL='https://drizzlebackend.awlworldwide.com/api'
// const BASE_URL= 'http://localhost:2008/api'
// const BASE_URL = 'https://upgraded-space-dollop-r44j674745wr2x44p-2008.app.github.dev/api'

export const UserLogin = async (userid, password) => {
    return axios.post(`${BASE_URL}/login`, { userid, password }).then(response => response.data).catch(error => console.log(error));
}

export const changePassword = async (user_id, password, CurrentPassword) => {
    return axios.post(`${BASE_URL}/ChangePassword`, { user_id, password, CurrentPassword }).then(response => response.data).catch(error => console.log(error));
}
export const insertUserLogin = async (user_name, user_id, user_password, DBname) => {
    return axios.post(`${BASE_URL}/insertuserlogin`, { user_name, user_id, user_password, DBname }).then(response => response.data).catch(error => console.log(error));
}

export const TotalCountry = async () => {
    return axios.get(`${BASE_URL}/totalcountry`).then(response => response.data).catch(error => console.log(error));
}
export const TotalState = async (country_id) => {
    return axios.post(`${BASE_URL}/totalstate`, { country_id }).then(response => response.data).catch(error => console.log(error));
}
export const TotalCity = async (state_id) => {
    return axios.post(`${BASE_URL}/totalcity`, { state_id }).then(response => response.data).catch(error => console.log(error));
}

export const UploadCity = async (datas) => {
    return axios.post(`${BASE_URL}/UploadCity`, { datas }).then(response => response.data).catch(error => console.log(error));
}
//Employee

export const TotalEmployees = async (org) => {
    return axios.post(`${BASE_URL}/totalEmployee`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const AddEmployees = async (org, employee_id, employee_name, location, employee_email, employee_number, company, user_id) => {
    return axios.post(`${BASE_URL}/insertEmployee`, { org, employee_id, employee_name, location, employee_email, employee_number, company, user_id }).then(response => response.data).catch(error => console.log(error));
}
export const GetEmployees = async (org, sno) => {
    return axios.post(`${BASE_URL}/getEmployee`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteEmployees = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteEmployee`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateEmployees = async (org, sno, employee_name, location, employee_email, employee_number, company, user_id) => {
    return axios.post(`${BASE_URL}/updateEmployee`, { org, sno, employee_name, location, employee_email, employee_number, company, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveEmployees = async (org) => {
    return axios.post(`${BASE_URL}/ActiveEmployee`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const EmployeesDetail = async (org, empid) => {
    return axios.post(`${BASE_URL}/EmployeeDetail`, { org, empid }).then(response => response.data).catch(error => console.log(error));
}


//Assets Type
export const TotalAssetTypeapi = async (org) => {
    return axios.post(`${BASE_URL}/totalAssetType`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const AddAssetTypeapi = async (org, asset_type_id, asset_type, asset_description, user_id) => {
    return axios.post(`${BASE_URL}/insertAssetType`, { org, asset_type_id, asset_type, asset_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetAssetTypeapi = async (org, sno) => {
    return axios.post(`${BASE_URL}/getAssetType`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteAssetTypeapi = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteAssetType`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateAssettypeapi = async (org, sno, asset_type, asset_description, user_id) => {
    return axios.post(`${BASE_URL}/updateAssetType`, { org, sno, asset_type, asset_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveAssetesType = async (org) => {
    return axios.post(`${BASE_URL}/ActiveAssetesType`, { org }).then(response => response.data).catch(error => console.log(error));
}

//Asset Status

export const TotalAssetStatusapi = async (org) => {
    return axios.post(`${BASE_URL}/totalAssetStatus`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const AddAssetStatusapi = async (org, asset_status_id, asset_status, asset_status_description, user_id) => {
    return axios.post(`${BASE_URL}/insertAssetStatus`, { org, asset_status_id, asset_status, asset_status_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteAssetStatusapi = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteAssetStatus`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const GetAssetStatusapi = async (org, sno) => {
    return axios.post(`${BASE_URL}/getAssetStatus`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateAssetStatusapi = async (org, sno, asset_status, asset_status_description, user_id) => {
    return axios.post(`${BASE_URL}/updateAssetStatus`, { org, sno, asset_status, asset_status_description, user_id }).then(response => response.data).catch(error => console.log(error));
}


export const ActiveAssetStatus = async (org) => {
    return axios.post(`${BASE_URL}/ActiveAssetesStatus`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Software Master

export const TotalSoftwareapi = async (org) => {
    return axios.post(`${BASE_URL}/totalSoftware`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const AddSoftwareapi = async (org, software_id, software_name, software_description, user_id) => {
    return axios.post(`${BASE_URL}/insertSoftware`, { org, software_id, software_name, software_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteSoftwaresapi = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteSoftware`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const GetSoftwareapi = async (org, sno) => {
    return axios.post(`${BASE_URL}/getSoftware`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateSoftwareapi = async (org, sno, software_name, software_description, user_id) => {
    return axios.post(`${BASE_URL}/updateSoftware`, { org, sno, software_name, software_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveSoftware = async (org) => {
    return axios.post(`${BASE_URL}/ActiveSoftware`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Purchase Master

export const TotalPurchaseTypeapi = async (org) => {
    return axios.post(`${BASE_URL}/totalPurchaseType`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const AddPurchaseTypeeapi = async (org, purchase_id, purchase_type, purchase_description, user_id) => {
    return axios.post(`${BASE_URL}/insertPurchaseType`, { org, purchase_id, purchase_type, purchase_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const DeletePurchaseTypeapi = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deletePurchaseType`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const GetPurchaseTypeapi = async (org, sno) => {
    return axios.post(`${BASE_URL}/getPurchaseType`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdatePurchaseapi = async (org, sno, purchase_type, purchase_description, user_id) => {
    return axios.post(`${BASE_URL}/updatePurchaseType`, { org, sno, purchase_type, purchase_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActivePurchaseTypeapi = async (org) => {
    return axios.post(`${BASE_URL}/ActivePurchasetype`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Priority Master

export const TotalPriorityapi = async (org) => {
    return axios.post(`${BASE_URL}/totalPriority`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const AddPriorityapi = async (org, priority_id, priority_type, priority_description, user_id) => {
    return axios.post(`${BASE_URL}/insertPriority`, { org, priority_id, priority_type, priority_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const DeletePriorityapi = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deletePriority`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const GetPriorityapi = async (org, sno) => {
    return axios.post(`${BASE_URL}/getPriority`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}
export const UpdatePriorityapi = async (org, sno, priority_type, priority_description, user_id) => {
    return axios.post(`${BASE_URL}/updatePriority`, { org, sno, priority_type, priority_description, user_id }).then(response => response.data).catch(error => console.log(error));
}


export const ActivePriority = async (org) => {
    return axios.post(`${BASE_URL}/ActivePriority`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Billing Frequency
export const TotalBillingFreqapi = async (org) => {
    return axios.post(`${BASE_URL}/totalBillingFrequency`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteBillingFreqapi = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteBillingFrequency`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}
export const AddBillingFreqapi = async (org, billing_freq_id, billing_freq, billing_freq_description, user_id) => {
    return axios.post(`${BASE_URL}/insertBillingFrequency`, { org, billing_freq_id, billing_freq, billing_freq_description, user_id }).then(response => response.data).catch(error => console.log(error));
}
export const GetBillingFreqapi = async (org, sno) => {
    return axios.post(`${BASE_URL}/getBillingFrequency`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateBillingFreqapi = async (org, sno, billing_freq, billing_freq_description, user_id) => {
    return axios.post(`${BASE_URL}/updateBillingFrequency`, { org, sno, billing_freq, billing_freq_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveBillingFreq = async (org) => {
    return axios.post(`${BASE_URL}/ActiveBillingFreq`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Vendor Category
export const TotalVendorCategoryapi = async (org) => {
    return axios.post(`${BASE_URL}/totalVendorCategory`, { org }).then(response => response.data).catch(error => console.log(error));
}
export const DeleteVendorCategoryapi = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteVendorCategory`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const AddVendorCategoryapi = async (org, vendor_category_id, vendor_category, vendor_category_description, user_id) => {
    return axios.post(`${BASE_URL}/insertVendorCategory`, { org, vendor_category_id, vendor_category, vendor_category_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetVendorCategoryapi = async (org, sno) => {
    return axios.post(`${BASE_URL}/getVendorCategory`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateVendorCategoryapi = async (org, sno, vendor_category, vendor_category_description, user_id) => {
    return axios.post(`${BASE_URL}/updateVendorCategory`, { org, sno, vendor_category, vendor_category_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveVendorCategory = async (org) => {
    return axios.post(`${BASE_URL}/getallvendorcategory`, { org }).then(response => response.data).catch(error => console.log(error));
}

//  Location Master

export const TotalLocation = async (org) => {
    return axios.post(`${BASE_URL}/totalLocation`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateLocationStatus = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteLocation`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const AddLocationapi = async (org, location_id, company_name, location_code, location_name, location_address_line1, location_address_line2, location_city, location_state, location_pin_code, location_gst, contact_person, contact_person_email, contact_person_number, location_latitude, location_longitude, user_id, location_country) => {
    return axios.post(`${BASE_URL}/insertLocation`, { org, location_id, company_name, location_code, location_name, location_address_line1, location_address_line2, location_city, location_state, location_pin_code, location_gst, contact_person, contact_person_email, contact_person_number, location_latitude, location_longitude, user_id, location_country }).then(response => response.data).catch(error => console.log(error));
}

export const GetLocation = async (org, sno) => {
    return axios.post(`${BASE_URL}/getLocation`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateLocation = async (org, sno, company_name, location_code, location_name, location_address_line1, location_address_line2, location_city, location_state, location_pin_code, location_gst, contact_person, contact_person_email, contact_person_number, location_latitude, location_longitude, user_id, location_country) => {
    return axios.post(`${BASE_URL}/updateLocation`, { org, sno, company_name, location_code, location_name, location_address_line1, location_address_line2, location_city, location_state, location_pin_code, location_gst, contact_person, contact_person_email, contact_person_number, location_latitude, location_longitude, user_id, location_country }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveLocation = async (org) => {
    return axios.post(`${BASE_URL}/getalllocation`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Manufacturer Master

export const TotalManufacturerapi = async (org) => {
    return axios.post(`${BASE_URL}/totalManufacturer`, { org }).then(response => response.data).catch(error => console.log(error));
}


export const UpdateManufacturerStatus = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteManufacturer`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}


export const InsertManufacturer = async (org, manufacturer_id, manufacturer_name, manufacturer_description, user_id) => {
    return axios.post(`${BASE_URL}/insertManufacturer`, { org, manufacturer_id, manufacturer_name, manufacturer_description, user_id }).then(response => response.data).catch(error => console.log(error));
}


export const GetManufacturer = async (org, sno) => {
    return axios.post(`${BASE_URL}/getManufacturer`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateManufacturer = async (org, sno, manufacturer_name, manufacturer_description, user_id) => {
    return axios.post(`${BASE_URL}/updateManufacturer`, { org, sno, manufacturer_name, manufacturer_description, user_id }).then(response => response.data).catch(error => console.log(error));
}


export const ActiveManufacturer = async (org) => {
    return axios.post(`${BASE_URL}/ActiveManufacturer`, { org }).then(response => response.data).catch(error => console.log(error));
}

// Issue Type Master

export const TotalIssueTypeapi = async (org) => {
    return axios.post(`${BASE_URL}/totalIssueType`, { org }).then(response => response.data).catch(error => console.log(error));
}


export const UpdateIssueTypeStatus = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteIssueType`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const InsertIssueType = async (org, issue_id, issue_type, issue_description, user_id) => {
    return axios.post(`${BASE_URL}/insertIssueType`, { org, issue_id, issue_type, issue_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetIssueType = async (org, sno) => {
    return axios.post(`${BASE_URL}/getIssueType`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateIssueType = async (org, sno, issue_type, issue_description, user_id) => {
    return axios.post(`${BASE_URL}/updateIssueType`, { org, sno, issue_type, issue_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveIssue = async (org) => {
    return axios.post(`${BASE_URL}/ActiveIssue`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Contract Type Master

export const TotalContractTypeapi = async (org) => {
    return axios.post(`${BASE_URL}/totalContractType`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateContractTypeStatus = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteContractType`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const InsertContractType = async (org, contract_id, contract_type, contract_description, user_id) => {
    return axios.post(`${BASE_URL}/insertContractType`, { org, contract_id, contract_type, contract_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetContractType = async (org, sno) => {
    return axios.post(`${BASE_URL}/getContractType`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateContractType = async (org, sno, contract_type, contract_description, user_id) => {
    return axios.post(`${BASE_URL}/updateContractType`, { org, sno, contract_type, contract_description, user_id }).then(response => response.data).catch(error => console.log(error));
}
export const ActiveContractType = async (org) => {
    return axios.post(`${BASE_URL}/getallcontracttype`, { org }).then(response => response.data).catch(error => console.log(error));
}


// Ticket Status Master

export const TotalTicketstatusapi = async (org) => {
    return axios.post(`${BASE_URL}/totalTicketStatus`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateTicketstatusActive = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteTicketStatus`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const InsertTicketstatus = async (org, ticket_id, ticket_status, ticket_description, user_id) => {
    return axios.post(`${BASE_URL}/insertTicketStatus`, { org, ticket_id, ticket_status, ticket_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetTicketstatus = async (org, sno) => {
    return axios.post(`${BASE_URL}/getTicketStatus`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateTicketstatus = async (org, sno, ticket_status, ticket_description, user_id) => {
    return axios.post(`${BASE_URL}/updateTicketStatus`, { org, sno, ticket_status, ticket_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveTicketStatus = async (org) => {
    return axios.post(`${BASE_URL}/ActiveTicketStatus`, { org }).then(response => response.data).catch(error => console.log(error));
}

// Vendor Sub Category Master

export const TotalVendSubCateapi = async (org) => {
    return axios.post(`${BASE_URL}/totalVendorSubCategory`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteVendSubCateStatus = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteVendorSubCategory`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const InsertVendSubCate = async (org, vendor_sub_category_id, vendor_category, vendor_sub_category, vendor_sub_category_description, user_id) => {
    return axios.post(`${BASE_URL}/insertVendorSubCategory`, { org, vendor_sub_category_id, vendor_category, vendor_sub_category, vendor_sub_category_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetVendSubCate = async (org, sno) => {
    return axios.post(`${BASE_URL}/getVendorSubCategory`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateVendSubCate = async (org, sno, vendor_category, vendor_sub_category, vendor_sub_category_description, user_id) => {
    return axios.post(`${BASE_URL}/updateVendorSubCategory`, { org, sno, vendor_category, vendor_sub_category, vendor_sub_category_description, user_id }).then(response => response.data).catch(error => console.log(error));
}
export const ActiveVendSubCate = async (org, vendor_category) => {
    return axios.post(`${BASE_URL}/getvendorsubcategorybyvend`, { org, vendor_category }).then(response => response.data).catch(error => console.log(error));
}

// Service Action Type Master

export const TotalServiceActionTypeapi = async (org) => {
    return axios.post(`${BASE_URL}/totalServiceAction`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteServiceActionTypeStatus = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteServiceAction`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const InsertServiceActionType = async (org, service_action_id, service_action_type, service_action_type_description, user_id) => {
    return axios.post(`${BASE_URL}/insertServiceAction`, { org, service_action_id, service_action_type, service_action_type_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetServiceActionType = async (org, sno) => {
    return axios.post(`${BASE_URL}/getServiceAction`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateServiceActionType = async (org, sno, service_action_type, service_action_type_description, user_id) => {
    return axios.post(`${BASE_URL}/updateServiceAction`, { org, sno, service_action_type, service_action_type_description, user_id }).then(response => response.data).catch(error => console.log(error));
}


// Service Group Master

export const TotalServiceGroupapi = async (org) => {
    return axios.post(`${BASE_URL}/totalServiceGroup`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteServiceGroupStatus = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteServiceGroup`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const InsertServiceGroup = async (org, service_group_id, service_group_type, service_group_description, user_id) => {
    return axios.post(`${BASE_URL}/insertServiceGroup`, { org, service_group_id, service_group_type, service_group_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const GetServiceGroup = async (org, sno) => {
    return axios.post(`${BASE_URL}/getServiceGroup`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateServiceGroup = async (org, sno, service_group_type, service_group_description, user_id) => {
    return axios.post(`${BASE_URL}/updateServiceGroup`, { org, sno, service_group_type, service_group_description, user_id }).then(response => response.data).catch(error => console.log(error));
}

// Vendor Code Master

export const TotalVendorCodeapi = async (org) => {
    return axios.post(`${BASE_URL}/totalVendorCode`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteVendorCode = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteVendorCode`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const InsertVendorCode = async (org, vendor_code_id, vendor_code, vendor_name, comp_email, comp_website, comp_gst,
    comp_phone, company_country_id, comp_country, comp_state_id, comp_state, comp_city, comp_pincode, comp_addr1, comp_addr2,
    vendor_portal, contact_person, contact_no, contact_email, user_id) => {
    return axios.post(`${BASE_URL}/insertVendorCode`, {
        org, vendor_code_id, vendor_code, vendor_name, comp_email, comp_website, comp_gst,
        comp_phone, company_country_id, comp_country, comp_state_id, comp_state, comp_city, comp_pincode, comp_addr1, comp_addr2,
        vendor_portal, contact_person, contact_no, contact_email, user_id
    }).then(response => response.data).catch(error => console.log(error));
}

export const GetVendorCode = async (org, sno) => {
    return axios.post(`${BASE_URL}/getVendorCode`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateVendorCode = async (org, sno, vendor_code, vendor_name, comp_gst, comp_website, comp_email, comp_phone, comp_country_id, comp_country,
    comp_state_id, comp_state, comp_city, comp_addr1, comp_addr2, comp_pincode, vendor_portal, contact_person, contact_no, contact_email, user_id) => {
    return axios.post(`${BASE_URL}/updateVendorCode`, {
        org, sno, vendor_code, vendor_name, comp_gst, comp_website, comp_email, comp_phone, comp_country_id, comp_country,
        comp_state_id, comp_state, comp_city, comp_addr1, comp_addr2, comp_pincode, vendor_portal, contact_person, contact_no, contact_email, user_id
    }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveVendorCode = async (org) => {
    return axios.post(`${BASE_URL}/getallvendor`, { org }).then(response => response.data).catch(error => console.log(error));
}
export const GetVendorDetails = async (org, vendor_name) => {
    return axios.post(`${BASE_URL}/getvendordetails`, { org, vendor_name }).then(response => response.data).catch(error => console.log(error));
}
// Vendor Contract Master

export const TotalVendorContractapi = async (org) => {
    return axios.post(`${BASE_URL}/totalVendorContract`, { org }).then(response => response.data).catch(error => console.log(error));
}
export const InsertVendorContract = async (org, vendor_contract_id, vendor, type_of_contract,
    major_category, sub_category, location, company, customer_account_no, reference_no, contact_plain_details,
    rate_per_month, contract_start_date, invoice_generation_date, billing_freq, payee_name, tds, link_id_no,
    help_desk_no, userid) => {
    return axios.post(`${BASE_URL}/InsertVendorContract`, {org,vendor_contract_id, vendor, type_of_contract,major_category, sub_category, location, company, customer_account_no, reference_no, contact_plain_details,
        rate_per_month, contract_start_date, invoice_generation_date, billing_freq, payee_name, tds, link_id_no,help_desk_no, userid
    }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateVendorContract = async (org, sno, vendor, type_of_contract,major_category, sub_category, location, company, customer_account_no, reference_no, contact_plain_details,
    rate_per_month, contract_start_date, invoice_generation_date, billing_freq, payee_name, tds, link_id_no,help_desk_no, user_id) => {
    return axios.post(`${BASE_URL}/UpdateVendorContract`, {org,sno, vendor, type_of_contract,major_category, sub_category, location, company, customer_account_no, reference_no, contact_plain_details,
        rate_per_month, contract_start_date, invoice_generation_date, billing_freq, payee_name, tds, link_id_no,help_desk_no, user_id
    }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteVendorContract = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/deleteVendorContract`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}

export const GetVendorContract = async (org, sno) => {
    return axios.post(`${BASE_URL}/getVendorContract`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const ActiveVendorContract = async (org) => {
    return axios.post(`${BASE_URL}/ActiveVendorContract`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const VendorContractDetail = async (org, sno) => {
    return axios.post(`${BASE_URL}/VendorContractDetail`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const VendorContractOnChange = async (org, value) => {
    return axios.post(`${BASE_URL}/vendorcontractonchange`, { org, value }).then(response => response.data).catch(error => console.log(error));
}

//  #########################   New Assets ##############################

export const TotalNewAssets = async (org,type) => {
    return axios.post(`${BASE_URL}/TotalNewAssets`, { org,type }).then(response => response.data).catch(error => console.log(error));
}

export const InsertNewAssets = async (org, new_asset_type_id, asset_type, asset_tag, serial_no, location, manufacture,
    software, model, asset_status, description, purchase_type, purchase_date, company, vendor, invoice_no,
    rent_per_month, purchases_price, latest_inventory, asset_name, asset_assign, asset_assign_empid, remarks, userid,vendor_code) => {
    return axios.post(`${BASE_URL}/InsertNewAssets`, {
        org, new_asset_type_id, asset_type, asset_tag, serial_no, location, manufacture,
        software, model, asset_status, description, purchase_type, purchase_date, company, vendor, invoice_no,
        rent_per_month, purchases_price, latest_inventory, asset_name, asset_assign, asset_assign_empid, remarks, userid,vendor_code
    }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteNewAssets = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/DeleteNewAssets`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}
export const GetNewAssets = async (org, sno) => {
    return axios.post(`${BASE_URL}/GetNewAssets`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const CountNewAssets = async (org, asset_type) => {
    return axios.post(`${BASE_URL}/CountNewAssets`, { org, asset_type }).then(response => response.data).catch(error => console.log(error));
}

export const GetNewAssetAssign = async (org, asset_assign_empid) => {
    return axios.post(`${BASE_URL}/GetNewAssetAssign`, { org, asset_assign_empid }).then(response => response.data).catch(error => console.log(error));
}
export const UpdateNewAssets = async (org, asset_type, assetetag, serialno, location, manufacture, software,
    model, assetstatus, description, purchase_type, purchasesdate, company, vendor, invoiceno,
    rentpermonth, purchaseprice, latestinventory, assetname, assetassign, asset_assign_empid, remark, userid, sno,vendor_code) => {
    return axios.post(`${BASE_URL}/UpdateNewAssets`, {org, asset_type, assetetag, serialno, location, manufacture, software,model, assetstatus, description, purchase_type, purchasesdate, company, vendor, invoiceno,
        rentpermonth, purchaseprice, latestinventory, assetname, assetassign, asset_assign_empid, remark, userid, sno,vendor_code
    }).then(response => response.data).catch(error => console.log(error));
}

//  #########################   New Assets SubCode ##############################

export const InsertAssetSubCode = async (org, asset_id, asset_tag, software) => {
    return axios.post(`${BASE_URL}/insertassetssoftware`, { org, asset_id, asset_tag, software }).then(response => response.data).catch(error => console.log(error));
}

//  #########################   Ticketes ##############################

export const InsertTicket = async (org, emp_id, emp_name, asset_type, asset_serial, location, assign_ticket, type_of_issue, email_id,
    ticket_date, ticket_status, ticket_subject, priority, issue_discription, remarks, user_id, AssetTag, AssetCondition) => {
    return axios.post(`${BASE_URL}/InsertTicket`, {org, emp_id, emp_name, asset_type, asset_serial, location, assign_ticket, type_of_issue, email_id,
        ticket_date, ticket_status, ticket_subject, priority, issue_discription, remarks, user_id, AssetTag, AssetCondition
    }).then(response => response.data).catch(error => console.log(error));
}

export const CountTickets = async (org) => {
    return axios.post(`${BASE_URL}/CountTickets`, { org }).then(response => response.data).catch(error => console.log(error));
}


export const TotalTicket = async (org) => {
    return axios.post(`${BASE_URL}/TotalTicket`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const DeleteTickets = async (org, status, sno) => {
    return axios.post(`${BASE_URL}/DeleteTickets`, { org, status, sno }).then(response => response.data).catch(error => console.log(error));
}
export const getTickets = async (org, sno) => {
    return axios.post(`${BASE_URL}/getTickets`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateTicket = async (org, emp_id, emp_name, asset_type, asset_serial, location, assign_ticket, type_of_issue, email_id,
    ticket_date, ticket_status, ticket_subject, priority, issue_discription, remarks, user_id, sno) => {
    return axios.post(`${BASE_URL}/UpdateTicket`, {org, emp_id, emp_name, asset_type, asset_serial, location, assign_ticket, type_of_issue, email_id,
        ticket_date, ticket_status, ticket_subject, priority, issue_discription, remarks, user_id, sno
    }).then(response => response.data).catch(error => console.log(error));
}

export const OpenTotalTicket = async (org) => {
    return axios.post(`${BASE_URL}/OpenTotalTicket`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const TotalHoldTicket = async (org) => {
    return axios.post(`${BASE_URL}/TotalHoldTicket`, { org }).then(response => response.data).catch(error => console.log(error));
}

//  Voice Invoice

export const InsertVendorInvoice = async (org, data, userid) => {
    return axios.post(`${BASE_URL}/InsertVendorInvoice`, { org, data, userid }).then(response => response.data).catch(error => console.log(error));
}
export const GetVendorInvoice = async (org, sno) => {
    return axios.post(`${BASE_URL}/GetVendorInvoice`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const PendingVendorInvoice = async (org) => {
    return axios.post(`${BASE_URL}/PendingVendorInvoice`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const PendingVendorInvoiceOnChnage = async (org, value) => {
    return axios.post(`${BASE_URL}/vendorinvoiceonchange`, { org, value }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateVendorInvoice = async (org, data, userid) => {
    return axios.post(`${BASE_URL}/UpdateVendorInvoice`, { org, data, userid }).then(response => response.data).catch(error => console.log(error));
}
export const UpdatePendingVendorInvoice = async (org, vendor, accountno, invno, invamt, invdate, invduedate, invsubdate, remark, refno, printercount, sno, filedata) => {
    return axios.post(`${BASE_URL}/UpdatePendingVendorInvoice`, { org, vendor, accountno, invno, invamt, invdate, invduedate, invsubdate, remark, refno, printercount, sno, filedata }).then(response => response.data).catch(error => console.log(error));
}

export const TotalVendorPaymentapi = async (org) => {
    return axios.post(`${BASE_URL}/TotalVendorPayment`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const GetVendorPayment = async (org, sno) => {
    return axios.post(`${BASE_URL}/GetVendorPayment`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateVendorPayment = async (org, paymentdetail, paymentamt, paymentdate, remark, sno, filedata, ApprovedAmt) => {
    return axios.post(`${BASE_URL}/UpdateVendorPayment`, { org, paymentdetail, paymentamt, paymentdate, remark, sno, filedata, ApprovedAmt }).then(response => response.data).catch(error => console.log(error));
}

export const UploadInvoice = async (org, type, document, sno) => {
    return axios.post(`${BASE_URL}/UploadInvoice`, { org, type, document, sno }).then(response => response.data).catch(error => console.log(error));
}

// Dashboard 

export const DashboarDetails = async (org) => {
    return axios.post(`${BASE_URL}/dashboard_details`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const DashboarProcedure = async (type) => {
    return axios.post(`${BASE_URL}/dashboard_procedure`, { type }).then(response => response.data).catch(error => console.log(error));
}

export const Dashboard_Location_Name = async (org) => {
    return axios.post(`${BASE_URL}/dashboard_location_name`, { org }).then(response => response.data).catch(error => console.log(error));
}
export const Dashboard_Software = async (org) => {
    return axios.post(`${BASE_URL}/dashboard_software`, { org }).then(response => response.data).catch(error => console.log(error));
}
export const Dashboard_Manufacture = async (org) => {
    return axios.post(`${BASE_URL}/dashboard_manufacture`, { org }).then(response => response.data).catch(error => console.log(error));
}

// Ticket Dashboard

export const Ticket_Summary = async (org, userid) => {
    return axios.post(`${BASE_URL}/ticket_summary`, { org, userid }).then(response => response.data).catch(error => console.log(error));
}

export const Ticket_Priority = async (org) => {
    return axios.post(`${BASE_URL}/ticket_priority`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const Ticket_issue_type = async (org) => {
    return axios.post(`${BASE_URL}/ticket_issue_type`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const Filter_Ticket_Summary = async (org, statustype, filterby, value) => {
    return axios.post(`${BASE_URL}/filter_ticket_summary`, { org, statustype, filterby, value }).then(response => response.data).catch(error => console.log(error));
}

export const Filter_Ticket_Summary_Count = async (org, type, value) => {
    return axios.post(`${BASE_URL}/filter_ticket_summary_count`, { org, type, value }).then(response => response.data).catch(error => console.log(error));
}

// Vendor Dashboard
export const Vendor_Reference_no = async (org) => {
    return axios.post(`${BASE_URL}/ReferanceNumber`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const Recurring_Vendor = async (org, pageno, rowsperpage) => {
    return axios.post(`${BASE_URL}/RecurringVendor`, { org, pageno, rowsperpage }).then(response => response.data).catch(error => console.log(error));
}

export const Recurring_Frequency = async (org, pageno, rowsperpage) => {
    return axios.post(`${BASE_URL}/RecurringFrequency`, { org, pageno, rowsperpage }).then(response => response.data).catch(error => console.log(error));
}

export const TotalVendorContract = async (org, pageno, rowsperpage) => {
    return axios.post(`${BASE_URL}/TotalVendorContractDetails`, { org, pageno, rowsperpage }).then(response => response.data).catch(error => console.log(error));
}

export const FilterVendorContract = async (org, type, value, pageno, rowsperpage) => {
    return axios.post(`${BASE_URL}/FilterVendorContract`, { org, type, value, pageno, rowsperpage }).then(response => response.data).catch(error => console.log(error));
}

export const ExportTotalVendorContract = async (org) => {
    return axios.post(`${BASE_URL}/exporttotalvendorcontract`, { org }).then(response => response.data).catch(error => console.log(error));
}

//Invoice Dashboard
export const Invoice_Outstanding = async (org) => {
    return axios.post(`${BASE_URL}/Invoice_Outstanding`, { org }).then(response => response.data).catch(error => console.log(error));
}
export const TotalOutstanding = async (org, pageno, rowsperpage) => {
    return axios.post(`${BASE_URL}/TotalOutstanding`, { org, pageno, rowsperpage }).then(response => response.data).catch(error => console.log(error));
}

export const VendorInvoice = async (org, pageno, rowsperpage,vendorname) => {
    return axios.post(`${BASE_URL}/VendorInvoice`, { org, pageno, rowsperpage,vendorname }).then(response => response.data).catch(error => console.log(error));
}
export const PaidInvoice = async (org, pageno, rowsperpage) => {
    return axios.post(`${BASE_URL}/PaidInvoice`, { org, pageno, rowsperpage }).then(response => response.data).catch(error => console.log(error));
}

export const FilterInvoice = async (org, value, pageno, rowsperpage) => {
    return axios.post(`${BASE_URL}/FilterInvoice`, { org, value, pageno, rowsperpage }).then(response => response.data).catch(error => console.log(error));
}

export const PendingRecurringInvoiceApi = async (org,billling_freq) => {
    return axios.post(`${BASE_URL}/recurringpendinginvoice`, { org,billling_freq }).then(response => response.data).catch(error => console.log(error));
}
export const Outstanding_Invoice_filter = async (org, type, value) => {
    return axios.post(`${BASE_URL}/outstandinginvoicefilter`, { org, type, value }).then(response => response.data).catch(error => console.log(error));
}

export const ExportOutstandingInvoiceData = async (org) => {
    return axios.post(`${BASE_URL}/exportoutstandinginvoicedata`, { org }).then(response => response.data).catch(error => console.log(error));
}

// User Details
export const getUserdetails = async (org, user_id) => {
    return axios.post(`${BASE_URL}/getuserdetails`, { org, user_id }).then(response => response.data).catch(error => console.log(error));
}

export const updateUserdetails = async (org, employee_name, location, employee_email, employee_number, company, user_id) => {
    return axios.post(`${BASE_URL}/updateuserdetails`, { org, employee_name, location, employee_email, employee_number, company, user_id }).then(response => response.data).catch(error => console.log(error));
}

// Organisation
export const AddOrganisation = async (org_id, org_name, org_country, org_state, org_city, org_currency, org_gst, org_logo) => {
    return axios.post(`${BASE_URL}/addorganisation`, { org_id, org_name, org_country, org_state, org_city, org_currency, org_gst, org_logo }).then(response => response.data).catch(error => console.log(error));
}

export const getOrganisation = async (org) => {
    return axios.post(`${BASE_URL}/getorganisation`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateOrganisationDetails = async (org, org_name, org_country, org_state, org_city, org_currency) => {
    return axios.post(`${BASE_URL}/updateorganizationdetails`, { org, org_name, org_country, org_state, org_city, org_currency }).then(response => response.data).catch(error => console.log(error));
}

// Currency Master

// export const CurrencyMaster = async () => {
//     const url = `https://country-info.p.rapidapi.com/`
//     return axios.get(url, {
//         headers: {
//             'X-RapidAPI-Key': '86b0c2197amshffb1cfe02f00926p1f0330jsn8771bfdd86c5',
//             'X-RapidAPI-Host': 'country-info.p.rapidapi.com'
//         }
//     }).then(response => response.data).catch(error => console.log(error))
// }

//Role Master
export const totalRoles = async (org) => {
    return axios.post(`${BASE_URL}/totalroles`, { org }).then(response => response.data).catch(error => console.log(error));
}

export const insertRoles = async (data) => {
    return axios.post(`${BASE_URL}/insertroles`, { data }).then(response => response.data).catch(error => console.log(error));
}

export const getrole = async (org, sno) => {
    return axios.post(`${BASE_URL}/getrole`, { org, sno }).then(response => response.data).catch(error => console.log(error));
}

export const Updaterole = async (data) => {
    return axios.post(`${BASE_URL}/updaterole`, { data }).then(response => response.data).catch(error => console.log(error));
}

//Report

export const ColumnsReport = async (org, table) => {
    return axios.post(`${BASE_URL}/ColumnsReport`, { org, table }).then(response => response.data).catch(error => console.log(error));
}

export const TableReports = async (org, table, columns) => {
    return axios.post(`${BASE_URL}/tableReports`, { org, table, columns }).then(response => response.data).catch(error => console.log(error));
}

export const GraphReport = async (org, table, columns) => {
    return axios.post(`${BASE_URL}/GraphReport`, { org, table, columns }).then(response => response.data).catch(error => console.log(error));
}

// Upload 
export const FileUpload = async (images) => {
    return axios.post(`${BASE_URL}/FileUpload`, images).then(response => response.data).catch(error => console.log(error));
}
// Mail
export const Mail = async (message) => {
    return axios.post(`${BASE_URL}/Email`, { message }).then(response => response.data).catch(error => console.log(error));
}
export const AssetEmail = async (message) => {
    return axios.post(`${BASE_URL}/assetemail`, { message }).then(response => response.data).catch(error => console.log(error));
}
export const InvoiceEmail = async (message) => {
    return axios.post(`${BASE_URL}/invoiceemail`, { message }).then(response => response.data).catch(error => console.log(error));
}

export const VendorPaymentEmail = async (message) => {
    return axios.post(`${BASE_URL}/vendorpaymentemail`, { message }).then(response => response.data).catch(error => console.log(error));
}

export const VendorCreateEmail = async (message) => {
    return axios.post(`${BASE_URL}/vendorcreateemail`, { message }).then(response => response.data).catch(error => console.log(error));
}

export const EmployeeCreateEmail = async (message) => {
    return axios.post(`${BASE_URL}/employeecreateemail`, { message }).then(response => response.data).catch(error => console.log(error));
}

export const VendorContractEmail = async (message) => {
    return axios.post(`${BASE_URL}/vendorcontractemail`, { message }).then(response => response.data).catch(error => console.log(error));
}

export const AssetReport = async ( location, vendor,assetStatus) => {
    return axios.post(`${BASE_URL}/assetreport`, {location, vendor,assetStatus }).then(response => response.data).catch(error => console.log(error));
}

export const dashboard_asset_data = async () => {
    return axios.post(`${BASE_URL}/dashboard_asset_data`, {}).then(response => response.data).catch(error => console.log(error));
}