import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import "./Reports.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ActiveLocation, ActiveVendorCode, AssetReport, ActiveAssetStatus } from "../../../api/index.js";
import customStyles from "../../TableCustomtyle";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

export default function AssetReports() {
  const [data, setData] = useState([]);
  const [locationlist, setLocationlist] = useState([]);
  const [vendorlist, setVendorlist] = useState([]);
  const [toogle, setToogle] = useState(false);
  const [dataCount, setDataCount] = useState({});
  const [assetStatusOption, setAssetStatusOption] = useState([]);
  const [assetStatusSelectedOpt, setAssetStatusSelectedOpt] = useState([])

  const columns = [
    {
      name: "Company",
      selector: "company",
      sortable: true,
    },

    {
      name: "Location",
      selector: "location",
      sortable: true,
    },

    {
      name: "Asset Type",
      selector: "asset_type",
      sortable: true,
    },
    {
      name: "Asset Assign",
      selector: "asset_assign",
      sortable: true,
    },
    {
      name: "Asset Status",
      selector: "asset_status",
      sortable: true,
    },
    {
      name: "Asset ID",
      selector: "new_asset_type_id",
      sortable: true,
    },
    {
      name: "Purchase Type",
      selector: "purchase_type",
      sortable: true,
    },
    {
      name: "Purchase Price",
      selector: "purchases_price",
      sortable: true,
    },
    {
      name: "Rent Per Month",
      selector: "rent_per_month",
      sortable: true,
    },
    {
      name: "Serial Number",
      selector: "serial_no",
      sortable: true,
    },
    {
      name: "Vendor",
      selector: "vendor",
      sortable: true,
    },
  ];

  useEffect(() => {
    const fetchdata = async () => {
      const org = localStorage.getItem("Database");
      const tablelocation = await ActiveLocation(org);
      setLocationlist(tablelocation);
      const vendorall = await ActiveVendorCode(localStorage.getItem("Database"));
      setVendorlist(vendorall);
      const assetAll = await ActiveAssetStatus(localStorage.getItem("Database"))
      let assetArr = assetAll.map((asset) => ({ value: asset.asset_status, label: asset.asset_status }))
      setAssetStatusOption(assetArr)
    };
    fetchdata();
  }, []);

  const handleLocationChange = async (e) => {
    let vendor = document.getElementById('Vendname').value
    const assetStatus = assetStatusSelectedOpt.length > 0 ? assetStatusSelectedOpt : ['All']
    getDataOnChange(e.target.value, vendor, assetStatus)
  };

  const handleVendorChange = async (e) => {
    let location = document.getElementById('Location').value;
    const assetStatus = assetStatusSelectedOpt.length > 0 ? assetStatusSelectedOpt : ['All']
    getDataOnChange(location, e.target.value, assetStatus)
  };

  const handleChangeAssetStatue = (e) => {
    let onlyVal = e.length ? e.map(a => a.value) : ['All']

    setAssetStatusSelectedOpt(onlyVal)
    let vendor = document.getElementById('Vendname').value
    let location = document.getElementById('Location').value;
    getDataOnChange(location, vendor, onlyVal)
  }

  const getDataOnChange = async (location, vendor, assetStatus) => {
    const result = await AssetReport(location, vendor, assetStatus);
    setData(result?.Data);
    setToogle(true);
    setDataCount(result?.Count);
  }

  const tableData = { columns, data };

  return (
    <>
      <Sidebar>
        <div
          className="main_container bg-white rounded" style={{ padding: "19px" }}>
          <h4>Asset Reports</h4>
          <div className="mt-1 card pb-3 ">
            <article className="card-body border-none">
              <div className="d-flex justify-content-end  rounded">
                {toogle && (
                  <div className="d-flex w-50 select_div text-center rounded bg-white">
                    <p className="mx-2">
                      Purchase Price:-
                      <span className="text-danger">{`${dataCount.purchases_price ? dataCount.purchases_price : 0}`}</span>
                    </p>
                    <p className="mx-2">
                      Rent Per Month:-
                      <span className="text-danger">{`${dataCount.rent_per_month ? dataCount.rent_per_month : '0'}`}</span>
                    </p>
                    <p className="mx-2">
                      Desktop:-
                      <span className="text-danger">{`${dataCount.Desktop ? dataCount.Desktop : '0'}`}</span>
                    </p>
                    <p className="mx-2">
                      Laptop:-
                      <span className="text-danger">{`${dataCount.Latptop ? dataCount.Latptop : '0'}`}</span>
                    </p>
                  </div>
                )}

                <div className="w-25 select_div text-center rounded bg-white">
                  <select className="form-select" aria-label="Default select example" id="Location" onChange={handleLocationChange}>
                    <option value="">  All</option>
                    {locationlist.map((item, index) => (
                      <option key={index} value={item.location_code}>
                        {item.location_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-25 select_div text-center mx-2 rounded bg-white">
                  <select className="form-select" aria-label="Default select example" id="Vendname" onChange={handleVendorChange}>
                    <option value=""> All</option>
                    {vendorlist.map((item, index) => (
                      <option key={index} value={[`${item.vendor_code}`]}>
                        {item.vendor_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-25">
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={assetStatusOption}
                    onChange={handleChangeAssetStatue}
                    id="assetStatus"
                  />
                </div>
              </div>
              {/* </div> */}
              <div className="d-flex m-2 justify-content-around">
                <div className="border border-dark overflow-auto pb-1 pt-2 px-2 rounded">
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                    customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </article>
          </div>
        </div>
      </Sidebar>
    </>
  );
}
