import React from 'react'
import HelpDeskSideBar from './HelpDeskSideBar'

export default function HelpSoftware() {
  return (
    <div>
    
        <div className='HelpDesk_container'>
           <h1>Software</h1>
           <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur vitae sapiente nam dolores fugiat illo 
            excepturi culpa asperiores velit voluptas maiores nisi officiis, voluptatum labore ullam repellat saepe
             praesentium unde animi quaerat laudantium voluptates incidunt non. Cum nulla optio dolore doloremque debitis 
             ipsam iure necessitatibus enim, incidunt numquam pariatur, quas omnis cupiditate aut adipisci odio, perferendis
              aspernatur distinctio velit ab tempora ad? Non, earum! Fugiat vero ipsam repellat veritatis ab quae unde,
               aperiam accusantium impedit non molestiae perferendis dolores eligendi, autem alias numquam.
             Porro, eos quis odio in repellat officiis. Molestiae sint quae veritatis a, iusto totam culpa nulla fugit.</p>
           </div>
        
    </div>
  )
}
