import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../../../Sidebar/Sidebar";
import { MdOutlineKeyboardArrowRight, MdFileUpload } from "react-icons/md";
import { ActiveVendorContract, VendorContractDetail, InsertVendorInvoice, FileUpload, VendorContractOnChange, InvoiceEmail } from "../../../../api";
import LoadingPage from "../../../LoadingPage/LoadingPage";
import { RiArrowGoBackFill } from "react-icons/ri";
import { GlobalAlertInfo } from "../../../../App";
import Modal from "../../AlertModal/Modal";
import "./addVendorInvoice.css";



function AddVendorInvoice() {
  const [loading, setLoading] = useState(false);
  const [imagLoading, setImagLoading] = useState()
  const [vendorcontractlist, setVendorcontractlist] = useState([]);
  const [todayDate, setTodayDate] = useState("");
  const [indexno, setIndexno] = useState();
  const [uploadindexno, setUploadindexno] = useState();
  let invRowObj = {
    vendor: 'select', accountno: '', invno: '', invamt: 0, invdate: todayDate, invduedate: todayDate, invsubdate: todayDate,
    remark: '', refno: '', printercount: 0, filedata: ''
  };
  const [invArrVal, setInvArrVal] = useState([invRowObj])
  const [fileUrl, setFileUrl] = useState('');

  // ########################### Modal Alert #############################################
  const { tooglevalue, callfun } = useContext(GlobalAlertInfo);
  // ########################### Modal Alert #############################################


  useEffect(() => {
    const fetchdata = async () => {
      const org = localStorage.getItem("Database");
      const vendorcontract = await ActiveVendorContract(org);
      setVendorcontractlist(vendorcontract);
      todaydate();
      setLoading(true);
    };
    fetchdata();
  }, []);

  const todaydate = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    let today = year + "-" + month + "-" + day;
    setTodayDate(today);
  };

  const handleAdd_Row = () => {
    setInvArrVal([...invArrVal, invRowObj])
  }
  const handleRemove_Row = () => {
    if (invArrVal.length !== 1) {
      let temInvArrVal = [...invArrVal];
      temInvArrVal.pop();
      setInvArrVal(temInvArrVal)
    }
  }
  const handleGetVendorName = async (e) => {
    const getname = await VendorContractOnChange(localStorage.getItem("Database"), e.target.value);
    setVendorcontractlist(getname);
  };

  const handleChnageVendorDetail = async (vendorData) => {
    const { Index, reference_no, sno, vendor } = vendorData
    const org = localStorage.getItem("Database");

    const detail = await VendorContractDetail(org, sno);
    let temInvArrVal = [...invArrVal];
    temInvArrVal[Index].vendor = vendor
    temInvArrVal[Index].accountno = detail.customer_account_no;
    temInvArrVal[Index].refno = detail.reference_no;
    temInvArrVal[Index].invdate = document.getElementById(`invdate-${Index}`).value;
    temInvArrVal[Index].invduedate = document.getElementById(`invduedate-${Index}`).value;
    temInvArrVal[Index].invsubdate = document.getElementById(`invsubdate-${Index}`).value;
    setInvArrVal(temInvArrVal)
  };


  const handleChangeRowData = (index, e) => {
    let temInvArrVal = [...invArrVal];
    temInvArrVal[index][e.target.name] = e.target.value
    setInvArrVal(temInvArrVal)
  }

  const handleSaveFileUrl = (index) => {
    let temInvArrVal = [...invArrVal];
    temInvArrVal[index].filedata = fileUrl
    setInvArrVal(temInvArrVal)
  }

//   const handleInsertVendorIvoice = async (e) => {
//     e.preventDefault();

//     document.getElementById("subnitbtn").disabled = "true";
//     setLoading(false);
//     const org = localStorage.getItem("Database");
//     let errorcount = 0;

//     for (let i = 0; i < invArrVal.length; i++) {
//       if (!invArrVal[i]) {
//         setLoading(true);
//         callfun("Please Select the vendor", "warning", "self");
//         document.getElementById("subnitbtn").disabled = false;

//         errorcount = errorcount + 1;
//         return false;
//       }
//       else if (!invArrVal[i].vendor) {
//         setLoading(true);
//         document.getElementById("subnitbtn").disabled = false;
//         callfun("Please Select the vendor", "warning", "self");

//         errorcount = errorcount + 1;
//         return false;
//       }
//       else if (!invArrVal[i].invno || !invArrVal[i].invamt) {
//         setLoading(true);
//         document.getElementById("subnitbtn").disabled = false;
//         callfun("Please enter the Mandatory field", "warning", "self");

//         errorcount = errorcount + 1;
//         return false;
//       }
//     }


//      if (errorcount === 0) {
//       setLoading(true);

//       const result = await InsertVendorInvoice(org, invArrVal, localStorage.getItem("UserId"));
// console.log("this is result", result)
//       if (result === "Data Added") {
//         for (let i = 0; i < invArrVal.length; i++) {
//           const message = {
//             type: "Add", invoiceno: invArrVal[i].invno, vendorname: invArrVal[i].vendor, reference_no: invArrVal[i].refno, invoice_date: invArrVal[i].invdate, invoice_receive_date: invArrVal[i].invsubdate, invoice_amount: invArrVal[i].invamt, upload: invArrVal[i].filedata || "",
//           };
//           await InvoiceEmail(message);
//         }
//         callfun("Vendor Invoice Added", "success", "/TotalVendorInvoice");
//       } else {
//         callfun("Server Error", "danger", "self");
//         document.getElementById("subnitbtn").disabled = false;
//       }
//     }
//   }


const handleInsertVendorIvoice = async (e) => {
  e.preventDefault();

  document.getElementById("subnitbtn").disabled = true; // Disable the submit button to prevent multiple submissions
  // setLoading(true); // Show loading state
  const org = localStorage.getItem("Database");
  let errorcount = 0;

  // Basic frontend validation to ensure mandatory fields are filled
  for (let i = 0; i < invArrVal.length; i++) {
      if (!invArrVal[i]) {
          // setLoading(false);
          callfun("Please Select the vendor", "warning", "self");
          document.getElementById("subnitbtn").disabled = false;
          errorcount += 1;
          return false;
      } else if (!invArrVal[i].vendor) {
          // setLoading(false);
          document.getElementById("subnitbtn").disabled = false;
          callfun("Please Select the vendor", "warning", "self");
          errorcount += 1;
          return false;
      } else if (!invArrVal[i].invno || !invArrVal[i].invamt) {
          // setLoading(false);
          document.getElementById("subnitbtn").disabled = false;
          callfun("Please enter the Mandatory field", "warning", "self");
          errorcount += 1;
          return false;
      }
  }
  // setLoading(false); // Show loading state


  // If no validation errors, proceed with the API call
  if (errorcount === 0) {
 
      try {
          // Make the API call to the backend
          const response = await fetch("https://drizzlebackend.awlworldwide.com/api/InsertVendorInvoice", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  org: org,
                  data: invArrVal,
                  userid: localStorage.getItem("UserId"),
              }),
          });
 
          const result = await response.text(); // Get the result from the API
          console.log("API Result:", result);

          // Handle success or error responses based on the API result
          if (response.ok && result === "Data Added Successfully") {
              for (let i = 0; i < invArrVal.length; i++) {
                  const message = {
                      type: "Add",
                      invoiceno: invArrVal[i].invno,
                      vendorname: invArrVal[i].vendor,
                      reference_no: invArrVal[i].refno,
                      invoice_date: invArrVal[i].invdate,
                      invoice_receive_date: invArrVal[i].invsubdate,
                      invoice_amount: invArrVal[i].invamt,
                      upload: invArrVal[i].filedata || "",
                  };
                  await InvoiceEmail(message); // Send invoice email
              }
              callfun("Vendor Invoice Added", "success", "/TotalVendorInvoice"); // Show success message and redirect
          } else if (result.includes("Duplicate entry found")) {
            document.getElementById("subnitbtn").disabled = false; // Disable the submit button to prevent multiple submissions

              callfun(result, "danger", "self"); // Show specific duplicate error from backend
          } else {
              callfun("Server Error", "danger", "self"); // Show generic server error
              document.getElementById("subnitbtn").disabled = false; // Re-enable submit button
          }
      } catch (err) {
          console.error("Error:", err);
          callfun("Server Error", "danger", "self"); // Handle server error
          document.getElementById("subnitbtn").disabled = false; // Re-enable submit button
      } finally {
          // setLoading(false); // Hide loading indicator
      }
  }
};


  return (
    <>
      {loading ? (
        <Sidebar>
          {/* ######################### Sanckbar Start ##################################### */}
          <Modal theme={tooglevalue.theme} text={tooglevalue.message} show={tooglevalue.modalshowval} url={tooglevalue.url} />
          {/* ######################### Sanckbar End ####################################### */}

          <div className="main_container px-1 w-100">
            <div className="main-inner-container d-flex justify-content-between  pt-4 pb-3">
              <h4>
                <span className="page-type-head1"> Vendor Invoice <MdOutlineKeyboardArrowRight /> </span>
                <span className="page-type-head2">Add Vendor Invoice</span>
              </h4>
              <button className="btn btn-secondary btn"
                onClick={() => { window.location.href = "/TotalVendorInvoice"; }}> Back <RiArrowGoBackFill /> </button>
            </div>
            <div className="bg-white shadow1-silver rounded15 mt-1 card inner-card pb-3">
              <header className="card-header d-flex justify-content-between align-items-center px-3">
                <h5>Add Vendor Invoice</h5>
                <div>
                  <button className="btn btn-voilet" onClick={handleAdd_Row}>Add row</button>
                  <button className="btn btn-secondary mx-2 " onClick={handleRemove_Row} >  Remove row</button>
                </div>
              </header>
              <article className="card-body">
                <form autoComplete="off" className="overflow-auto">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="text-center">
                        <th scope="col"> Vendor <span className="text-danger">*</span> </th>
                        <th scope="col"> Account No<span className="text-danger">*</span></th>
                        <th scope="col"> Invoice No <span className="text-danger">*</span></th>
                        <th scope="col"> Invoice Amt <span className="text-danger">*</span> </th>
                        <th scope="col"> Invoice Date <span className="text-danger">*</span></th>
                        <th scope="col"> Invoice DueDate <span className="text-danger">*</span> </th>
                        <th scope="col"> Invoice SubDate <span className="text-danger">*</span></th>
                        <th scope="col"> Remark </th>
                        <th scope="col"> Ref no. <span className="text-danger">*</span> </th>
                        <th scope="col"> Reading</th>
                        <th scope="col"> Upload</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invArrVal.map((item, index) => (
                        <tr key={index}>
                          <td className="p-0 ">
                            <button type="button" id={`button${index}`} className="btn btn-primary" data-toggle="modal" data-target="#vendorModalCenter"
                              onClick={(e) => {
                                setIndexno(index);
                                setTimeout(() => { document.getElementById("searchInvoice").focus(); }, 200);
                              }}
                            >
                              {invArrVal[index].vendor}
                            </button>
                          </td>
                          <td className="p-0 ">
                            <input type="text" id={`accountno-${index}`} name="accountno" className="form-control m-0  border-0" disabled
                              defaultValue={invArrVal[index].accountno}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="text" id={`invno-${index}`} name="invno" className="form-control m-0  border-0"
                              defaultValue={invArrVal[index].invno}
                              onBlur={(e) => { handleChangeRowData(index, e) }}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="number" id={`invamt-${index}`} name="invamt" className="form-control m-0  border-0"
                              defaultValue={invArrVal[index].invamt}
                              onBlur={(e) => { handleChangeRowData(index, e) }}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="date" id={`invdate-${index}`} name="invdate" className="form-control m-0  border-0"
                              defaultValue={todayDate}
                              onChange={(e) => { handleChangeRowData(index, e) }}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="date" id={`invduedate-${index}`} name="invduedate" className="form-control m-0  border-0"
                              defaultValue={todayDate}
                              onChange={(e) => { handleChangeRowData(index, e) }}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="date" id={`invsubdate-${index}`} name="invsubdate" className="form-control m-0  border-0"
                              defaultValue={todayDate}
                              onChange={(e) => { handleChangeRowData(index, e) }}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="text" id={`remark-${index}`} name="remark" className="form-control m-0  border-0"
                              defaultValue={invArrVal[index].remark}
                              onBlur={(e) => { handleChangeRowData(index, e) }}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="text" id={`refno-${index}`} name="refno" className="form-control m-0  border-0" disabled
                              defaultValue={invArrVal[index].refno}
                            />
                          </td>
                          <td className="p-0 ">
                            <input type="text" id={`printercount-${index}`} name="printercount" className="form-control m-0  border-0"
                              defaultValue={invArrVal[index].printercount}
                              onBlur={(e) => { handleChangeRowData(index, e) }}
                            />
                          </td>
                          <td className="p-0 ">
                            <button className="form-control m-0  border-0" data-toggle="modal" data-target="#exampleModalCenter"
                              onClick={(e) => {
                                e.preventDefault();
                                setUploadindexno(index);
                                document.getElementById(
                                  "uploadbutton"
                                ).style.display = "none";
                                document.getElementById("inputfile").value = "";
                              }}
                            >
                              <MdFileUpload style={{ fontSize: "25px", color: invArrVal[index].filedata ? "green" : "" }} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="btn_div mx-2">
                    <button className="btn btn-voilet" id="subnitbtn"
                      onClick={handleInsertVendorIvoice}
                    >  Submit </button>
                    <button type="reset" className="btn btn-secondary mx-2"> Reset </button>
                  </div>
                </form>
              </article>
            </div>
          </div>
        </Sidebar>
      ) : (
        <LoadingPage />
      )}

      {/* ####################### Upload  Modal  Start ################################## */}

      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">  Upload Invoice </h5>
            </div>
            <div className="modal-body">
              <input type="file" id="inputfile"
                onChange={async (event) => {
                  setImagLoading(true);
                  setTimeout(async () => {
                    const data = new FormData();
                    data.append("images", event.target.files[0]);
                    const UploadLink = await FileUpload(data);
                    if (UploadLink.length > 1) {
                      setFileUrl(UploadLink);
                      document.getElementById("uploadbutton").style.display = "flex";
                      setImagLoading(false);
                    }
                  }, 2000);
                }}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="uploadbutton" data-dismiss="modal" style={{ display: "none" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveFileUrl(uploadindexno)
                }} >
                Upload
              </button>
              {imagLoading ? "Wait a Sec" : null}
            </div>
          </div>
        </div>
      </div>
      {/* ####################### Upload  Modal  ENd ################################## */}

      {/* ####################### Vendor  Modal  Start ################################## */}
      <div className="modal fade" id="vendorModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered" role="document" style={{ minWidth: "53vw" }} >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">  Select Vendor Name </h5>
              <div className="form-group col-md-5">
                <input type="text" className="form-control col" placeholder="Enter vendor name to search " id="searchInvoice"
                  onChange={handleGetVendorName}
                />
              </div>
            </div>
            <div className="modal-body overflow-auto position-relative p-0" style={{ maxHeight: "60vh" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Vendor Name</th>
                    <th>Reference no</th>
                    <th>Account no</th>
                    {/* <th>Printer Count</th> */}
                  </tr>
                </thead>
                <tbody>
                  {vendorcontractlist.map((item, index) => (
                    <tr key={index} className="cursor-pointer" data-dismiss="modal" value={`${item.sno},${item.vendor}`}
                      onClick={(e) => {
                        handleChnageVendorDetail({
                          sno: item.sno,
                          vendor: item.vendor,
                          reference_no: item.reference_no,
                          Index: indexno,
                        });
                      }}
                    >
                      <td>{item.company}</td>
                      <td>{item.vendor}</td>
                      <td>{item.reference_no}</td>
                      <td>{item.customer_account_no}</td>
                      {/* <td>{item.printer_counter}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" > Close</button>
            </div>
          </div>
        </div>
      </div>
      {/* ####################### Vendor  Modal  End ################################## */}
    </>
  );
}

export default AddVendorInvoice;